@import '../../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; */
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);
  background: #fcf8f2;

  @media (--viewportLarge) {
    padding: 0 24px;
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
}

.logoLink:hover {
  /* border-bottom: 4px solid var(--marketplaceColor); */
  border-radius: 0;
}

/* Search */
.searchLink {
  min-width: 320px;
  height: 100%;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.takeAvailableSpace {
  flex-grow: 1;

  /* At 1024px, the translations might need more space than what searchLink otherwise gets. */
  min-width: 220px;
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  padding-right: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }

  & input {
    background: #FCF8F2;

  }
}

/* These is used with Inbox, Sign up, and Log in */
.topbarLink {
  flex-shrink: 0;

  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    /* color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0; */
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  pointer-events: none;
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.menubarDesktop {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .rightTopSection {
    & span {
      padding-left: 30px;
    }
  }
}

.menuButton {
  position: relative;

  & .menuDropDownLogin {
    position: absolute;
    top: 72px;
    left: -24px;
    width: 434px;
    height: 560px;
    background-color: #5B4C23;
  }

  & .menuDropDownLogOut {
    position: absolute;
    top: 47px;
    left: -24px;
    width: 434px;
    height: 560px;
    background-color: #5B4C23;
  }

  & .crossButton {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  & .dropdownWrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    padding-top: 80px;
  }

  & .dropdownLink {
    & a {
      padding: 0;
    }

    & span {
      font-family: "Josefin Sans";
      font-size: 25px !important;
      font-weight: 400;
      line-height: 35.16px;
      letter-spacing: 0.05em;
      text-align: center;
      color: #ffffff;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }

  }

  & .leftProfileSection {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  & .outsideHandle {
    display: block !important;
  }

}

.categoryDropdown {
  font-family: "Josefin Sans";
  font-size: 18px !important;
  font-weight: 500;
  line-height: 35.16px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #ffffff;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.cartLink {
  position: relative;

  & .cartItems {
    background-color: #A28930;
    color: #F8F4F3;
    font-size: 13px;
    line-height: normal;
    position: absolute;
    right: -9px;
    bottom: -12px;
    font-family: 'karla', sans-serif;
    font-weight: 500;
    border-radius: 45px;
    height: 20px;
    width: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
  }
}